
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { EsService } from "@/services/es.service";
import { SearchData } from "@/models/search.model";
import JnFacebookSharing from "@/views/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "@/views/sharing/LinkedinSharing.vue";
import JnPrint from "@/views/common/Print.vue";
import JnWord from "@/views/common/Word.vue";
import JnPaywall from "@/views/auth/Paywall.vue";
import { JnService } from "@/services/jn.service";
import { DossierDocument } from "@/models/dossier.document.model";
import { uuid } from "vue-uuid";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { Dossier } from "@/models/dossier.model";
import EventBus from "@/services/event.bus";
import MetaInfo from "vue-meta";
import JnProgress from "@/views/common/Progress.vue";
import { CategoriesFormulari } from "@/models/enums.model";
import { User } from "@/models/crm/user.model";
import { Purchase } from "@/models/crm/purchase.model";

@Component({
  components: {
    JnFacebookSharing,
    JnLinkedinSharing,
    JnPrint,
    JnWord,
    JnPaywall,
    JnProgress,
  },
  metaInfo(this: JnJuranewsDoc): MetaInfo {
    return {
      title: "JuraNews - " + this.doc?.title,
      meta: [
        { property: 'og:title', content: "JuraNews - " + this.doc?.title }
      ]
    };
  },
})
export default class JnJuranewsDoc extends Vue {
  @Prop({ required: true })
  id!: string;

  jwtToken = process.env.VUE_APP_API_KEY!
  loading = true;
  alert = false;
  alertMessage = "";
  alertTimeout = 6000;

  paywall = false;

  keywords = "";
  queryType = "";
  showHighlight = false;

  get authenticated() {
    return String(this.$cookies.get("username")) != "null";
  }

  doc? = new Document({
    id: "0",
    seq: 0,
    target: "",
    type: "",
    isFragment: false,
    title: "",
    imageSrc: "",
    abstract: "",
    date: "",
    tags: [],
    categories: [],
    idCategory: "",
    idAreaTematica: "",
    areaTematica: "",
    idMateria: "",
    idArgomento: "",
    materia: "",
    argomento: "",
    author: "",
    descrAuthor: "",
    size: 0,
    highlights: [],
    index: "",
    access: 1,
    since: "",
    to: "",
    sezioneCassazione: "",
    content: "",
    text: "",
    abrogato: false,
    note: [],
    massime: [],
  });

  async mounted() {
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      id: this.id,
      size: 1,
      from: 0,
      withText: true,
    });

    const usernameFromCookie = this.$cookies.get("username");
    if (this.$cookies.get("jwtToken")) {
      this.jwtToken = String(this.$cookies.get("jwtToken"));
    }
    if (usernameFromCookie && this.jwtToken) {
      const userCookie = this.$cookies.get("vtiger_user") as User;
      const purchaseCookie = this.$cookies.get("vtiger_purchase_order") as Purchase;
      const user = userCookie ? userCookie as User: undefined;
      const purchase = purchaseCookie ? purchaseCookie as Purchase: undefined;
      /*const user = await JnService.getCrmUser(
        String(usernameFromCookie),
        undefined
      );
      const purchase = await JnService.getActivePurchaseOrder(user.id);*/
      let crmInfo = undefined;
      console.log("JuranetDoc(mounted)(purchase): " + purchase);
      if (user) {
        if (purchase) {
          const service = await JnService.getService(purchase.serviceId);
          crmInfo = new CrmInfo(user, purchase, service);
        } else {
          crmInfo = new CrmInfo(user, undefined, undefined);
        }
      }
      if (this.doc?.access == 1 || purchase) {
        this.paywall = true;
      }
      this.$store.commit("crmInfo", crmInfo);
    }

    EventBus.$on("doc-create-start", () => {
      this.loading = true;
    });
    EventBus.$on("doc-create-stop", () => {
      console.log("DOC CREATE STOPPED");
      this.loading = false;
    });
    this.doc = await EsService.getDoc(searchData, this.jwtToken);

    this.loading = false;

    this.keywords = String(this.$route.query.keywords);
    this.showHighlight = true;

    await JnService.addAccessDocStat(
      usernameFromCookie ? (usernameFromCookie as string) : "",
      this.doc?.id!,
      window.location.href
    );
  }

  isFormulario() {
    return (
      CategoriesFormulari.includes(String(this.doc!.idCategory))
    );
  }


  /**
   * GESTIONE DOSSIER - INIZIO
   **/
  dossier = new Dossier(String(this.$cookies.get("username")), []);
  targetDossier!: DossierDocument | undefined;
  showAdd = false;

  async selectTargetDossier(selectedDocument: DossierDocument[]) {
    const targetDossierDocument = selectedDocument[0];
    this.targetDossier = targetDossierDocument;
    console.log(
      "JuranewsDoc.selectTargetDossier:" + JSON.stringify(this.targetDossier)
    );
  }
  async addToDossier() {
    const username = String(this.$cookies.get("username"));
    this.dossier.username = username;
    let addDirectToDossier = false;
    try {
      this.dossier = await JnService.getDossier(username);
      console.log(
        "JuranewsDoc.addToDossier(existing-dossier):" +
        JSON.stringify(this.dossier)
      );
      if (this.dossier.hasDossier()) {
        this.showAdd = true;
      } else {
        addDirectToDossier = true;
      }
    } catch (err) {
      console.log("JuranewsDoc.addToDossier(err):" + JSON.stringify(err));
      addDirectToDossier = true;
    }
    if (addDirectToDossier) {
      const newDossierDocument = new DossierDocument(
        "",
        this.doc?.title,
        this.doc?.text,
        [],
        false
      );
      const response = await JnService.saveDossierDocument(newDossierDocument);
      newDossierDocument.id = response.id;
      const newDossier = new DossierDocument(
        uuid.v1(),
        "Nuovo fascicolo",
        undefined,
        [],
        true
      );
      newDossier.addDoc(newDossierDocument);
      this.dossier.addDoc(newDossier);
      this.alertMessage =
        "Il documento è stato aggiunto al fascicolo 'Nuovo fascicolo'. Accedi all'area utente per gestire i tuoi fascicoli";
      this.alert = true;
      this.dossier = await JnService.saveDossier(this.dossier);
      EventBus.$emit("dossier", this.dossier);
    }
  }

  async saveAdd() {
    if (this.targetDossier && !this.targetDossier.isDossier) {
      this.alertMessage = "Selezionare un fascicolo.";
      this.alert = true;
    } else {
      const newDossierDocument = new DossierDocument(
        "",
        this.doc?.title,
        this.doc?.text,
        [],
        false
      );
      const response = await JnService.saveDossierDocument(newDossierDocument);
      newDossierDocument.id = response.id;
      this.showAdd = false;
      if (!this.targetDossier) {
        const nuovoFascicolo = new DossierDocument(
          uuid.v1(),
          "Nuovo fascicolo",
          undefined,
          [],
          true
        );
        nuovoFascicolo.addDoc(newDossierDocument);
        this.dossier.addDoc(nuovoFascicolo);
        this.alertMessage =
          "Il documento è stato aggiunto al fascicolo 'Nuovo fascicolo'. Accedi all'area utente per gestire i tuoi fascicoli";
        this.alert = true;
      } else {
        this.targetDossier.addDoc(newDossierDocument);
        this.alertMessage =
          "Il documento è stato aggiunto al fascicolo '" +
          this.targetDossier.name +
          "'. Accedi all'area utente per gestire i tuoi fascicoli";
        this.alert = true;
      }
      this.dossier = await JnService.saveDossier(this.dossier);
      EventBus.$emit("dossier", this.dossier);
    }
  }
  /**
   * GESTIONE DOSSIER - FINE
   **/

  get paywallOk(): boolean {
    const crmInfo = this.$store.getters.crmInfo;
    if (this.doc?.access == 1 || (crmInfo && crmInfo.purchase)) {
      this.paywall = true;
    } else {
      this.paywall = false;
    }
    return this.paywall;
  }

  @Watch("showHighlight")
  async switchShowHighlight() {
    if (this.keywords!.trim() != "") {
      if (this.queryType != "EXACT") {
        this.keywords = this.keywords
          ?.replaceAll(" i ", " ")
          .replaceAll(" I ", " ")
          .replaceAll(" a ", " ")
          .replaceAll(" A ", " ")
          .replaceAll(" e ", " ")
          .replaceAll(" E ", " ")
          .replaceAll(" o ", " ")
          .replaceAll(" O ", " ")
          .replaceAll(" del ", " ")
          .replaceAll(" dei ", " ")
          .replaceAll(" delle ", " ")
          .replaceAll(" della ", " ")
          .replaceAll(" un ", " ")
          .replaceAll(" una ", " ")
          .replaceAll(" in ", " ")
          .replaceAll(" il ", " ")
          .replaceAll(" lo ", " ")
          .replaceAll(" la ", " ")
          .replaceAll(" di ", " ")
          .replaceAll(" da ", " ");
      }
      
      const words = this.queryType == "EXACT" ? this.keywords!.trim().split("§§") : this.keywords?.split(" ");

      for (const w of words) {
        if (this.showHighlight) {
          const reg = new RegExp(w, "ig");
          this.doc!.text = this.doc!.text!.replace(reg, "<mark>" + w + "</mark>");
        } else {

          console.log(this.keywords)
          const strReplace = "<mark>" + w + "</mark>";
          const reg = new RegExp(strReplace, "ig");
          this.doc!.text = this.doc!.text!.replace(reg, w);
        }
      }

    }
  }
}
